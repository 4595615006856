import React from "react";
import PropTypes from "prop-types";
import { NewPasswordRequired } from "./NewPasswordRequired";
import { SmsMfa } from "./SmsMfa";
import { SoftwareTokenMfa } from "./SoftwareTokenMfa";

export const Challenges = ({ challenge }) => {
  if (challenge.name === "NEW_PASSWORD_REQUIRED") {
    return (
      <NewPasswordRequired
        session={challenge.session}
        username={challenge.username}
      />
    );
  }

  if (challenge.name === "SMS_MFA") {
    return <SmsMfa session={challenge.session} username={challenge.username} />;
  }

  if (challenge.name === "SOFTWARE_TOKEN_MFA") {
    return (
      <SoftwareTokenMfa
        session={challenge.session}
        username={challenge.username}
      />
    );
  }

  return null;
};

Challenges.propTypes = {
  challenge: PropTypes.shape({
    username: PropTypes.string,
    name: PropTypes.string,
    session: PropTypes.string,
  }).isRequired,
};
