import React from "react";
import QRCode from "react-qr-code";
import PropTypes from "prop-types";

import { qrCodeValueGenerator } from "../../lib/qr-code-value-generator";

export const QrCodeRenderer = ({ email, secretCode }) => {
  return (
    <div className="self-center" data-testid="qr-code">
      <QRCode value={qrCodeValueGenerator(email, secretCode)} />
    </div>
  )
};

QrCodeRenderer.propTypes = {
  secretCode: PropTypes.string,
};
QrCodeRenderer.defaultProps = {
  secretCode: "",
};
