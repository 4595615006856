import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { fetchSecretCode } from "../../api/fetch-secret-code";
import { SingleColumnDiv, Spacer } from "../../components";
import { QrCodeRenderer } from "./QrCodeRenderer";
import { SecretCodeRenderer } from "./SecretCodeRenderer";
import { SendUserCode } from "./SendUserCode";
import { MFAInstructions } from "./MFAInstructions";
import { notifyError } from "../../lib/notifications";

export const AssociateSoftwareToken = ({ email }) => {
  const [secretCode, setSecretCode] = useState(null);

  const history = useHistory();

  useEffect(() => {
    fetchSecretCode()
      .then((res) => {
        setSecretCode(res.data.secret_code);
      })
      .catch((error) => {
        if (!error.response) {
          return notifyError("Network error");
        }

        if (error.response.status === 401) {
          notifyError("Access token expired");
          return history.push("/login");
        }

        return notifyError("Something went wrong");
      });
    // eslint-disable-next-line
  }, []);

  return (
    <SingleColumnDiv>
      <MFAInstructions />
      {secretCode && <QrCodeRenderer email={email} secretCode={secretCode} />}
      <Spacer />
      {secretCode && <SecretCodeRenderer secretCode={secretCode} />}
      {secretCode && <SendUserCode />}
    </SingleColumnDiv>
  );
};
