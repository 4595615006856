import React from "react";

export const RedCross = () => {
  return (
    <button
      type="button"
      className="bg-red-400 h-6 rounded-full flex items-center justify-center group focus:outline-none focus:shadow-outline w-full"
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8,8 L15,15" id="Line" stroke="#FFFFFF" strokeWidth="2" />
        <path d="M8,15 L15,8" stroke="#FFFFFF" strokeWidth="2" />
      </svg>
    </button>
  )
};
