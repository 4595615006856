import { settings } from "./settings";

const { passwordRules, locale } = settings;
const defaultLocale = "enUS";

const dict = {
  enUS: {
    title: "Log in to Breathe",
    login: "Login",
    signup: "Signup",
    signupTo: "Signup to:",
    greeting: "Welcome back",
    email: "Email address",
    emailPlaceholder: "boom@shakalaka.com",
    password: "Password",
    passwordPlaceholder: "S3cr3t_Shhh",
    passwordConfirmation: "Password confirmation",
    emailNotice: "We'll never share your email with anyone else.",
    rememberMe: "Remember me on this device",
    loginButton: "Log in",
    appleSignInButton: "Sign in with apple",
    appleBioButton: "Sign in with FaceID",
    forgotPasswordTitle: "Haven't received an email?",
    forgotPasswordText:
      "Please check your spam and junk folders before contacting us.",
    forgotPasswordLink: "Forgot your password?",
    forgottenPassword: "Reset your password",
    noUnlock: "I didn't receive unlock instructions.",
    rememberPasswordQuestion: "Remembered your password?",
    rememberPassword: "Go back",
    resetPassword: "Reset password",
    setPassword: "Set your password",
    setPasswordButton: "Let's go",
    emailIssue: "That does not appear to be a correct email",
    passwordIssues: {
      noPassword: "No password",
      minLength: `Your password needs at least ${
        passwordRules.minLength
      } character${passwordRules.minLength > 1 ? "s" : ""} `,
      upperCase: "Your password needs at least one upper case character",
      lowerCase: "Your password needs at least one lower case character",
      number: "Your password needs at least one number",
      symbol: "Your password needs at least one symbol",
      confirmationMatch: "This confirmation doesn't match",
    },
    wrongEmail: "Your email looks wrong",
    wrongPassword: "Your password looks wrong",
    oneTimeCodeTitle: "One Time Code",
    oneTimeCodeDesc:
      "You just received a password somewhere, somehow through the magic of Werner Vogels, Open Source Software, the internet and mostly, the scientific revolution.",
    oneTimeCodeButton: "Trust but verify",
    pickApplication: "Hi",
    pickApplication2: ", where to?",
    newPasswordTitle: "Choose a new password",
    resetPasswordTitle: "Choose a new password",
    resetPasswordCTA: "Update password",
    warningTitle: "Planned maintenance",
    warningText:
      "Breathe will be down for planned maintenance for a couple of hours on the morning of Sunday 13th December.",
    changePasswordTitle: "Change your password",
    changePassword: "Change password",
    currentPassword: "Current password",
    newPassword: "New password",
    passwordRules: "Your password should be at least 8 characters and include 1 upper case letter, 1 lower case letter and 1 number. There can be no blank spaces at the beginning or end of the password"
  },
  enEN: {
    title: "BreatheAuth🏴󠁧󠁢󠁥󠁮󠁧󠁿",
    login: "Enter",
    signup: "Join the Fun",
    greeting: "How do you do Guv'nor",
    email: "Email",
    password: "Password",
  },
  enWA: {
    title: "BreatheAuth🐑🏴󠁧󠁢󠁷󠁬󠁳󠁿🐉",
  },
  enSC: {
    title: "BreatheAuth🏴󠁧󠁢󠁳󠁣󠁴󠁿🦄",
  },
  scGA: {
    title: "Dearbhadh dearbhaidh anail🏴‍☠️",
  },
  irGA: {
    title: "fíordheimhniú breathe󠁧󠁢󠁳󠁣󠁴 🍺🍀🇮🇪",
  },
};

export const language = (entry, object = null) => {
  if (object) {
    return dict[locale]?.[object]?.[entry]
      ? dict[locale][object][entry]
      : "Not in dictionnary";
  }

  return dict[locale]?.[entry]
    ? dict[locale][entry]
    : dict[defaultLocale][entry];
};
