const form = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const block = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
};

export const animations = {
  block,
  form,
};
