import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useStoreActions } from "easy-peasy";

import { redirectAfterLogin } from "../lib/redirect-after-login";
import { Modal } from "../components/Modal";
import { smsMfa } from "../api/sms-mfa";

export const SmsMfa = ({ session, username }) => {
  const setIsAuthenticated = useStoreActions((actions) => actions.setIsAuthenticated);
  const [code, setCode] = useState(null);
  const history = useHistory();

  const handleClick = (_e) => {
    const body = { code, session, username };

    smsMfa(body)
      .then((_res) => {
        setIsAuthenticated(true);
        return redirectAfterLogin(history);
      })
      .catch((err) => {
        console.log(err)
      });
  }

  return (
    <Modal isOpen contentLabel="Modal">
      <div className="flex flex-col items-center py-5 px-5 sm:px-16 text-sm bg-grey-light">
        <h1 className="text-black font-medium text-2xl mb-5 tracking-tight">
          Please type your code
        </h1>
        <input
          className="px-4 py-3 rounded text-black tracking-widest w-full mb-5"
          aria-label="authorization-code"
          placeholder="Code"
          onChange={(e) => setCode(e.target.value)}
          type="text"
        />
        <input
          type="submit"
          className="btn mb-5 btn-green max-w-138 w-full h-36"
          onClick={handleClick}
          value="Send"
        />
      </div>
    </Modal>
  )
};

SmsMfa.propTypes = {
  session: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
}
