import React from "react";
import { BREATHE_BLUE } from "../constants";

export const BackgroundCurve = () => {

  return (
    <div className="absolute top-0 left-0 right-0 w-full z-minus-1">
      <svg
        preserveAspectRatio="none"
        width="100%"
        height="auto"
        viewBox="0 0 1600 765"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        className="svg-curve"
      >
        <g
          id="background-curve"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g fill={BREATHE_BLUE}>
            <path
              d="M-0.452783653,312.597656 C153.66995,402.29315 341.313938,469.239492 560.290316,507.828259 C1070.53844,597.74593 1275.51987,587 1599.54722,765 C1599.54722,765 1599.54722,510 1599.54722,0 L-0.452783653,0 C-1.18240545,208.398437 -1.18240545,312.597656 -0.452783653,312.597656 Z"
              id="curve-extended"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};
