import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import { useStoreState, useStoreActions } from "easy-peasy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { SHOW_WARNING as level } from "../constants";

import { login } from "../api/login";
import { settings, language, animations } from "../settings";
import {
  SignupTabs,
  Password,
  Email,
  LoginLinks,
  Warning,
} from "../components";
import { redirectAfterLogin } from "../lib/redirect-after-login";
import { Challenges } from "../challenges/Challenges";
import { notifyError } from "../lib/notifications";
import { checkEmailValid } from "../helpers";

export const Login = () => {
  const setIsAuthenticated = useStoreActions(
    (actions) => actions.setIsAuthenticated,
  );
  const setChallenge = useStoreActions(
    (actions) => actions.setChallenge,
  );
  const challenge = useStoreState((state) => state.challenge);

  const history = useHistory();

  const [username, setUsername] = useState("");
  const usernameIsValidEmail = () => {
    return checkEmailValid(username);
  };
  const usernameIsNotValidEmail = () => {
    return !usernameIsValidEmail();
  }
  const params = new URLSearchParams(window.location.search);
  const isExistingUser = params.get("existing_user") === "1"

  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const setProfile = useStoreActions((actions) => actions.setProfile);

  const { animate, signUp } = settings;
  const { block, form } = animations;

  const signIn = (e) => {
    setLoading(true);
    e.preventDefault();
    const body = { username, password };

    login(body)
      .then((res) => {
        if (res.data.challenge) {
          setChallenge(res.data.challenge);
          return true;
        }

        if (res.data.mfa_setup_request) {
          setProfile({ email: username });
          history.push("/associate-software-token");
          return true;
        }

        setIsAuthenticated(true);
        return redirectAfterLogin(history);
      })
      .catch((error) => {
        setLoading(false);
        if (!error.response) {
          return notifyError("Network error");
        }
        console.log(error)
        if (error.response.status === 401) {
          return notifyError("Incorrect username or password");
        }

        return notifyError("Something went wrong");
      });

    return null;
  };

  return (
    <>
      <Helmet>
        <title>{language("title")}</title>
      </Helmet>
      <h1 className="text-center text-black font-medium text-2xl mb-8 tracking-tight">
        {language("title")}
      </h1>
      {signUp && <SignupTabs />}
      {challenge && <Challenges challenge={challenge} />}
      <Warning level={level}>
        <p className="text-sm font-bold">{language("warningTitle")}</p>
        <p className="text-xs">{language("warningText")}</p>
      </Warning>
      { isExistingUser &&
        (
        <div
          className="mb-5 mx-10 bg-yellow-100 border-t-4 border-yellow-500 rounded-b text-yellow-900 px-4 py-3 shadow-md"
          role="alert"
        >
          It looks like you already have a Breathe account. Please log in below.
        </div>
)}
      <motion.form
        variants={form}
        initial={animate ? "hidden" : ""}
        animate={animate ? "show" : ""}
      >
        <motion.div
          variants={block}
          className="flex flex-col w-full pb-4 pt-10 px-4 sm:px-16 text-sm bg-grey-light "
        >
          <Email
            username={username}
            setUsername={setUsername}
          />
        </motion.div>

        <motion.div
          variants={block}
          className="flex flex-col w-full px-4 sm:px-16 text-sm bg-grey-light"
        >
          <Password
            password={password}
            setPassword={setPassword}
          />
        </motion.div>

        <motion.div variants={block} className="centered bg-grey-light pb-6">
          <button
            type="submit"
            className={`btn mb-2 btn-green max-w-138 w-full h-36
              ${usernameIsValidEmail() ? "grows" : "disabled"}`}
            onClick={signIn}
            disabled={usernameIsNotValidEmail()}
          >
            {loading ? (
              <FontAwesomeIcon icon={faSpinner} spin aria-hidden />
            ) : (
              language("loginButton")
            )}
          </button>
        </motion.div>

        <motion.div variants={block} className="centered py-10 text-sm">
          <LoginLinks username={username} setError={() => {}} />
        </motion.div>
      </motion.form>
    </>
  );
};
