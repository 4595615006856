import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useStoreActions, useStoreState } from "easy-peasy";

import { login } from "../../api/login";

import { useAuthorizeWithDoorkeeper } from "./AuthorizeWithDoorkeeper";

import { notifyError } from "../../lib/notifications";
import { checkEmailValid } from "../../helpers";
import { LoginForm } from "./components/LoginForm";
import { RedirectionMessage } from "./components/RedirectionMessage";

export const LoginWithBreathe = () => {
  const setIsAuthenticated = useStoreActions(
    (actions) => actions.setIsAuthenticated,
  );
  const isAuthenticated = useStoreState((state) => state.isAuthenticated);
  const setChallenge = useStoreActions(
    (actions) => actions.setChallenge,
  );

  const history = useHistory();

  const [username, setUsername] = useState("");
  const usernameIsValidEmail = () => {
    return checkEmailValid(username);
  };
  const usernameIsNotValidEmail = () => {
    return !usernameIsValidEmail();
  }

  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [callOnce, setCallOnce] = useState(false)

  const setProfile = useStoreActions((actions) => actions.setProfile);
  const authorizeWithDoorkeeper = useAuthorizeWithDoorkeeper(setLoading, setCallOnce);

  const signIn = () => {
    setLoading(true);

    const body = { username, password };

    login(body)
      .then((res) => {
        if (res.data.challenge) {
          setChallenge(res.data.challenge);
          return true;
        }

        if (res.data.mfa_setup_request) {
          setProfile({ email: username });
          history.push("/associate-software-token");
          return true;
        }

        setIsAuthenticated(true);

        authorizeWithDoorkeeper(setLoading, setCallOnce);
        return true;
      })
      .catch((error) => {
        setLoading(false);
        if (!error.response) {
          return notifyError("Network error");
        }
        if (error.response.status === 401) {
          return notifyError("Incorrect username or password");
        }
        return notifyError("Something went wrong");
      });

    return null;
  };

  useEffect(() => {
    if (isAuthenticated && !callOnce) {
      authorizeWithDoorkeeper();
    }
  }, [authorizeWithDoorkeeper, callOnce, isAuthenticated]);

  return (
    <>
      {isAuthenticated
        ? <RedirectionMessage />
        : (
          <LoginForm
            username={username}
            setUsername={setUsername}
            password={password}
            setPassword={setPassword}
            loading={loading}
            usernameIsValidEmail={usernameIsValidEmail}
            usernameIsNotValidEmail={usernameIsNotValidEmail}
            signIn={signIn}
          />
        )}
    </>
  );

};