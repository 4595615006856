import React, { useState } from "react";
import PropTypes from "prop-types";

import { GreenTick } from "./GreenTick";
import { RedCross } from "./RedCross";

export const FieldErrorIcon = ({ errors }) => {
  const [showErrorMessages, setShowErrorMessages] = useState(false);

  const toggleShowErrorMessages = () => {
    setShowErrorMessages(!showErrorMessages);
    setTimeout(() => {
      setShowErrorMessages(false);
    }, 2000);
  };

  const anyErrors = () => errors.length > 0;

  return (
    <>
      <div
        onMouseEnter={() => toggleShowErrorMessages()}
        onFocus={() => toggleShowErrorMessages()}
      >
        {anyErrors() ? <RedCross /> : <GreenTick />}
      </div>
      <div
        className={`bg-purple-600 text-white w-auto overflow-visible min-w-full rounded transition-all duration-300 mt-1 right-0 absolute z-10 ${showErrorMessages ? "visible" : "invisible"} height-${showErrorMessages ? "full" : "0"}`}
      >
        {errors.map((issue, index) => {
          return (
            <div className="text-xs p-1" key={`issue${index}`}>
              {issue}
            </div>
          );
        })}
      </div>
    </>
  );
};

FieldErrorIcon.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
};
