import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";

import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import "./styles.css";
import { StoreProvider } from "easy-peasy";
import store from "./store";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const app = (
  <StoreProvider store={store}>
    <ReactNotification />
    <App />
  </StoreProvider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
