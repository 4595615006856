import React from "react";
import PropTypes from "prop-types";

export const GreyDiv = ({ children }) => {
  return (
    <div
      className="flex flex-col w-full py-10 px-4 sm:px-16 text-sm bg-grey-light mb-3"
    >
      {children}
    </div>
  );
};

GreyDiv.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired,
  ]).isRequired,
};
