export const settings = {
  locale: "enUS",
  signUp: false,
  greeting: false,
  gravatar: true,
  animate: true,
  passwordRules: {
    minLength: 8,
    upperCase: true,
    lowerCase: true,
    number: true,
    symbol: false,
  },
  bioLogin: {
    iOs: false,
    android: false,
  },
  oAuth: {
    apple: false,
    linkedin: false,
    googleSpyLogin: false,
    facebookSpyLogin: false,
  },
  captcha: {
    honeypot: true,
    homemade: false,
    cloudflareHCaptcha: true,
    googleMoneyThiefCaptcha: false,
  },
  signupFields: [
    "email",
    "companyName",
    "firstName",
    "lastName",
    "password",
    "passwordConfirm",
  ],
};
