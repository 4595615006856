import React from "react";
import PropTypes from "prop-types";

export const ApplicationCard = ({ image, name, application }) => {
  return (
    <div
      className="bg-white w-full h-full rounded shadow-lg flex flex-col"
      style={{ minHeight: "170px" }}
    >
      <div
        className={`pt-2 flex justify-center items-center rounded-t ${application} white h-20`}
      >
        <img
          className="w-full p-6 content-center"
          src={image}
          alt={`${name} logo`}
        />
      </div>
      <div className="flex-grow" />
      <button
        className={`${application} text-white p-2 w-1/2 self-center my-2 rounded `}
        type="submit"
      >
        Go
      </button>
    </div>
  );
};

ApplicationCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  application: PropTypes.string.isRequired,
};

export default ApplicationCard;
