import React, { useState } from "react";
import PropTypes from "prop-types";
import { Eyecon } from ".";

import { language } from "../settings";
import { FieldErrorIcon } from "./FieldErrorIcon";

export const PasswordConfirmation = ({
  password,
  passwordConfirmation,
  setPasswordConfirmation,
}) => {
  const [inputType, setInputType] = useState("password");
  const togglePasswordVisibility = () => {
    const newType = inputType === "password" ? "text" : "password";
    setInputType(newType);
  };
  const onChange = (e) => {
    setPasswordConfirmation(e.target.value);
  };

  const collectErrors = () => {
    const errors = [];
    if (password !== passwordConfirmation) {
      errors.push("Passwords must match");
    }

    return errors;
  };

  return (
    <>
      <div className="flex w-full items-center relative mb-5">
        <input
          placeholder=" "
          type={inputType}
          name="password-confirmation"
          autoComplete="off"
          aria-label="Password confirmation"
          value={passwordConfirmation}
          className={`password-confirmation password-input px-4 py-3 rounded text-black tracking-widest w-full ${
            passwordConfirmation.length > 0 && collectErrors().length > 0
              ? "issues"
              : ""
          }`}
          onChange={onChange}
        />
        <label
          htmlFor="password-input"
          className="text-grey absolute ml-4 transition ease-in duration-300 ie-fix pointer-events-none"
        >
          {language("passwordConfirmation")}
        </label>
        <Eyecon
          show={inputType !== "password"}
          toggleVisibility={togglePasswordVisibility}
          passwordPresent={passwordConfirmation.length > 0}
        />
        <div
          className={`transition-all duration-300 ml-1 ${
            passwordConfirmation.length > 0 ? "w-6" : "w-0"
          }`}
        >
          {passwordConfirmation.length > 0 && (
            <FieldErrorIcon errors={collectErrors()} />
          )}
        </div>
      </div>
    </>
  );
};

PasswordConfirmation.propTypes = {
  password: PropTypes.string.isRequired,
  passwordConfirmation: PropTypes.string.isRequired,
  setPasswordConfirmation: PropTypes.func.isRequired,
};
