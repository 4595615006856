import { LOGIN_API_URL } from "../constants";

const config = {
  baseURL: LOGIN_API_URL,
  timeout: 5000,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
};

export default config;
