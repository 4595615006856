import React from "react";

export const SmallText = ({ children }) => {
  return (
    <div className="px-4 sm:px-16 text-gray-500 text-xs">
      {children}
    </div>
  )
};

