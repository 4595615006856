import { useEffect } from "react";
import { useStoreActions } from "easy-peasy";

import { fetchProfile } from "../api/fetch-profile";

export const FetchProfile = () => {
  const setProfile = useStoreActions((actions) => actions.setProfile);

  useEffect(() => {
    fetchProfile()
      .then((res) => setProfile(res.data))
      .catch((e) => console.log(e));
  }, []);

  return null;
};
