import React, { useEffect, useState } from "react";
import { useStoreState } from "easy-peasy";
import { css } from "@emotion/react";
import { FadeLoader } from "react-spinners";
import { fetchApplications } from "../api/applications";
import { APPLICATIONS } from "../lib/applications";
import { HR_APP_URL } from "../constants";
import { AnimatedLink, ApplicationCard } from "../components";


export const Applications = () => {
  const [applications, setApplications] = useState([]);

  useEffect(() => {
    fetchApplications()
      .then((response) => {
        setApplications(response.data.applications);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const applicationDetails = (application) => {
    return APPLICATIONS[application];
  };

  const greetingCSS = css`
    display: block;
    margin: 25px auto;
  `;

  const greeting = useStoreState(
    (state) => {
      if(state.profile) {
        if (applications.length !== 0) {
          return (
            <h2 className="font-bold p-3 text-center">
              {`Hey ${state.profile.first_name}, where to?`}
            </h2>
          )
        } else {
          return (
            <div>
              <h2 className="font-bold p-3 text-center">
                {`Hey ${state.profile.first_name}!`}
              </h2>
              <p className="p-3 text-center">
                It doesn&apos;t look like you have access to any applications yet. You can&nbsp;
                <a
                  href={`${HR_APP_URL}/signup`}
                  className="underline text-blue-600 hover:text-blue-800 hover:underline"
                >
                sign up here
                </a>
                &nbsp;in just a few clicks.
              </p>
            </div>
          )
        }
      }
        return <FadeLoader color='#0580fa' loading css={greetingCSS} />;
    },
  );

  return (
    <>
      { greeting }
      <div className="flex flex-wrap justify-around">
        {applications.map((application, index) => {
          const applicationDetail = applicationDetails(application);
          if(!applicationDetail) return null;

          return (
            <AnimatedLink
              url={applicationDetail.url}
              index={index}
              key={applicationDetail.name}
            >
              <ApplicationCard
                image={applicationDetail.image}
                name={applicationDetail.name}
                application={application}
              />
            </AnimatedLink>
          );
        })}
      </div>
    </>
  );
};
