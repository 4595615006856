import React from "react";
import { language } from "../settings";
import { HR_APP_URL, SINGUP_URL } from "../constants";

export const SignupLink = () => {
  const signUpUrl = () => {
    return SINGUP_URL === undefined ? `${HR_APP_URL}/signup` : SINGUP_URL;
  };

  return (
    <>
      <a href={signUpUrl()} title={language("signup")}>
        <button
          type="button"
          className="text-gray-500 underline hover:text-blue-900 hover:underline"
        >
          {language("signup")}
        </button>
      </a>
    </>
  );
};
