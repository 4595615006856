import React from "react";
import { gb } from "../lib/locale";

export const BreatheLogo = () => {
  const breatheUrl = () => {
    if(gb()) {
      return "https://www.breathehr.com/";
    }

    return "https://www.breathehr.com/en-au/";
  }

  return (
    <div className="logo">
      <a href={breatheUrl()}>
        <img src="/design/images/breathe_logo.svg" alt="logo" />
      </a>
    </div>
  );
};
