import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useStoreActions } from "easy-peasy";

import { verifySoftwareToken } from "../../api/verify-software-token";
import { SingleColumnDiv, GreyDiv } from "../../components";
import { notifyError } from "../../lib/notifications";

export const SendUserCode = () => {
  const [userCode, setUserCode] = useState(null);
  const  history = useHistory();
  const setIsAuthenticated = useStoreActions(
    (actions) => actions.setIsAuthenticated,
  );

  const userCodeNotProvided = () => ( userCode === null || userCode.length === 0 );

  const handleSubmit = (event) => {
    event.preventDefault();

    if(userCodeNotProvided()) {
      return notifyError("One time code missing");
    }

    const body = {
      user_code: userCode,
    };

    return verifySoftwareToken(body)
      .then((_res) => {
        setIsAuthenticated(true);

        return history.push("/applications");
      })
      .catch((error) => {
        if (!error.response) {
          return notifyError("Network error");
        }

        if (error.response.status === 400) {
          return notifyError("Wrong or expired code");
        }

        return notifyError("Something went wrong");
      });
  };

  return (
    <SingleColumnDiv>
      <GreyDiv>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col w-full mb-4"
        >
          <input
            className="px-4 py-3 rounded text-black tracking-widest w-full mb-5"
            aria-label="user-code"
            placeholder="User code"
            onChange={(e) => setUserCode(e.target.value)}
            type="text"
          />
          <input
            type="submit"
            className="btn mb-5 btn-green h-36 self-center"
            value="Send user code"
          />
        </form>
      </GreyDiv>
    </SingleColumnDiv>
  );
};
