import React from "react";
import PropTypes from "prop-types";
import { useStoreState } from "easy-peasy";

import { gb } from "../lib/locale";
import { Logout } from "./Logout";
import { BackgroundCurve } from "./BackgroundCurve";
import { BreatheLogo } from "./BreatheLogo";
import { Footer } from "./Footer";
import { FooterAu } from "./FooterAu";

export const Layout = ({ children }) => {
  const isAuthenticated = useStoreState((state) => state.isAuthenticated);

  return (
    <>
      <BackgroundCurve />
      <nav className="max-w-1240 flex justify-between w-full p-4 pt-8 mx-auto">
        <BreatheLogo />
        {isAuthenticated && <Logout />}
      </nav>
      <div className="h-full min-80-vh">
        <div className="flex justify-center mt-10 mb-20">{children}</div>
      </div>
      {gb() ? <Footer /> : <FooterAu />}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.shape({ attribute: PropTypes.string }),
};
Layout.defaultProps = {
  children: null,
};
