import React, { useState } from "react";
import PropTypes from "prop-types";
import { language } from "../settings";
import { Eyecon } from ".";

export const Password = ({ password, setPassword, label }) => {
  const [showPassword, setShowPassword] = useState(false);
  const toggleVisibility = () => {
    setShowPassword((current) => !current)
  }

  const onChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <>
      <div className="flex w-full items-center relative mb-5">
        <input
          placeholder=" "
          type={showPassword ? "text" : "password"}
          name="password"
          autoComplete="off"
          aria-label={label}
          value={password}
          className="px-4 py-3 rounded text-black tracking-widest w-full password-input"
          onChange={onChange}
        />
        <label
          htmlFor="password-input"
          className="text-grey absolute ml-4 transition ease-in duration-300 ie-fix pointer-events-none"
        >
          {label}
        </label>
        <Eyecon
          show={showPassword}
          toggleVisibility={toggleVisibility}
        />
      </div>
    </>
  );
};

Password.propTypes = {
  password: PropTypes.string.isRequired,
  setPassword: PropTypes.func.isRequired,
  label: PropTypes.string,
};

Password.defaultProps = {
  label: language("password"),
};
