import React, { useState } from "react";

import {
  CenteredDiv,
  ConfirmButton,
  GreyDiv,
  NormalHeader,
  PageTitle,
  Password,
  PasswordWithValidation,
  PasswordConfirmation,
  PasswordInfo,
} from "../components";
import { changePassword } from "../api/change-password";
import { notifySuccess, notifyError } from "../lib/notifications";
import { language } from "../settings/language";

export const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const [newPasswordValid, setNewPasswordValid] = useState(false);

  const passwordMatchConfirmation = () => {
    return newPassword === newPasswordConfirmation;
  };

  const formValid = () => {
    return (
      newPasswordValid && passwordMatchConfirmation()
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!formValid()) {
      return false;
    }

    const body = {
      currentPassword,
      newPassword,
    };

    return changePassword(body)
      .then((_res) => {
        notifySuccess("Password has been changed");
        setCurrentPassword("");
        setNewPassword("");
        setNewPasswordConfirmation("");
      })
      .catch((error) => {
        if (!error.response) {
          return notifyError("Network error");
        }

        if (error.response.status === 401) {
          return notifyError("Wrong password");
        }

        if (error.response.status === 429) {
          return notifyError("Too many requests, please try again later");
        }

        return notifyError("Something went wrong");
      });
  };

  return (
    <>
      <PageTitle title={language("changePasswordTitle")} />
      <NormalHeader>{language("changePasswordTitle")}</NormalHeader>
      <GreyDiv>
        <form onSubmit={handleSubmit} className="flex flex-col w-full">
          <Password
            password={currentPassword}
            setPassword={setCurrentPassword}
            label="Current password"
          />
          <PasswordWithValidation
            password={newPassword}
            setPassword={setNewPassword}
            setPasswordValid={setNewPasswordValid}
            label={language("newPassword")}
          />
          <PasswordConfirmation
            password={newPassword}
            passwordConfirmation={newPasswordConfirmation}
            setPasswordConfirmation={setNewPasswordConfirmation}
          />
        </form>
        <div className="flex flex-col items-center">
          <PasswordInfo />
        </div>

      </GreyDiv>
      <CenteredDiv>
        <ConfirmButton
          onClick={handleSubmit}
          text={language("changePassword")}
          disabled={!formValid()}
        />
      </CenteredDiv>
    </>
  );
};
