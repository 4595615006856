export const ENV = process.env.REACT_APP_ENV;
export const LOCALE = process.env.REACT_APP_LOCALE;
export const BREATHE_DOMAIN = process.env.REACT_APP_BREATHE_DOMAIN;
export const BREATHE_ID_TOKEN = process.env.REACT_APP_BREATHE_ID_TOKEN;
export const SINGUP_URL= process.env.REACT_APP_SIGNUP_URL;

export const COGNITO_URL = process.env.REACT_APP_COGNITO_URL;

export const INTERCOM_2FA_HELP_ARTICLE = process.env.REACT_APP_INTERCOM_2FA_HELP_ARTICLE

export const LOGIN_API_URL = process.env.REACT_APP_LOGIN_API_URL;
export const LOGIN_APP_URL = process.env.REACT_APP_LOGIN_APP_URL;
export const LOGIN_APP_ID = process.env.REACT_APP_LOGIN_APP_ID;

export const ROTA_APP_URL = process.env.REACT_APP_ROTA_APP_URL;
export const HR_APP_URL = process.env.REACT_APP_HR_URL;

export const SHOW_WARNING = process.env.REACT_APP_SHOW_WARNING;

export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export const BREATHE_BLUE = "#0580FA";
