import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useStoreActions } from "easy-peasy";

import { oauthTokenFromCognito } from "../api/oauth-token";

export const FetchTokens = ({ code }) => {
  const setIsAuthenticated = useStoreActions(
    (actions) => actions.setIsAuthenticated,
  );
  const history = useHistory();

  useEffect(() => {
    oauthTokenFromCognito({ code })
      .then((_res) => {
        setIsAuthenticated(true);
        history.push("/applications");
      })
      .catch((e) => {
        console.log(e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
