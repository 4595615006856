import React from "react";

export const GreenTick = () => {
  return (
    <div className="bg-green-400 h-6 rounded-full flex items-center justify-center group focus:outline-none focus:shadow-outline w-full">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="12"
        viewBox="0 0 24 24"
        fill="#FFF"
      >
        <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
      </svg>
    </div>
  );
};
