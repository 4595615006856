import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export const RedirectionMessage = () => {

  return (
    <>
      <h1 className="text-center text-black font-medium text-2xl mb-8 tracking-tight">
        Sign in with Breathe
      </h1>

      <div className="flex flex-col w-full pb-4 pt-10 px-4 sm:px-16 text-sm text-center my-5 centered bg-grey-light">
        <span>Please hold on, we’re securely redirecting you to your destination...</span>
        <span><FontAwesomeIcon icon={faSpinner} spin aria-hidden /></span>
      </div>
    </>
  );
};
