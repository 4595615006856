import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons"
import { INTERCOM_2FA_HELP_ARTICLE } from "../constants";
import { Spacer } from "../components";

const Help = () => {
  return (
    <ul className="list-disc px-16 text-sm">
      <li className="text-gray-600">Your organisation has switched on two-factor authentication to keep your data safe.</li>
      <li className="text-gray-600">To access your account, open your authenticator app and enter the one-time code that appears. </li>
      <li className="text-gray-600">
        <a target="_blank" rel="noopener noreferrer" href={INTERCOM_2FA_HELP_ARTICLE}>More help</a>
      </li>
    </ul>
  )
}

export const SoftwareTokenHelp = () => {
  const [showHelp, setShowHelp] = useState(false)
  const handleClick = (e) => {
    e.preventDefault();
    setShowHelp(!showHelp);
  };

  return (
    <div>
      <button className="float-right" data-testid="token-help" type="button" onClick={handleClick}>
        <FontAwesomeIcon icon={faQuestion} />
      </button>
      <Spacer />
      {showHelp && <Help /> }
      <Spacer />
    </div>
  )
};
