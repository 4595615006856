import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useStoreActions } from "easy-peasy";

import { language } from "../settings";
import { redirectAfterLogin } from "../lib/redirect-after-login";
import { Modal } from "../components/Modal";
import { createPasswordChallenge } from "../api/create-password-challenge";
import {
  PasswordWithValidation,
  PasswordConfirmation,
  PasswordInfo,
} from "../components";
import { notifyError } from "../lib/notifications";

export const NewPasswordRequired = ({ session, username }) => {
  const setIsAuthenticated = useStoreActions(
    (actions) => actions.setIsAuthenticated,
  );
  const setChallenge = useStoreActions(
    (actions) => actions.setChallenge,
  );
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const passwordMatchConfirmation = () => {
    return password === passwordConfirmation;
  };

  const handlePasswordResetFailed = (e) => {
    setIsOpen(false);
    if (e.response && e.response.data && e.response.data.error) {
      return notifyError(e.response.data.error);
    }
  };

  const clickHandle = () => {
    const body = { username, password, session };
    if (passwordValid && passwordMatchConfirmation()) {
      createPasswordChallenge(body)
        .then((res) => {
          if (res.data.challenge) {
            setChallenge(res.data.challenge);
            return true;
          }

          setIsAuthenticated(true);
          return redirectAfterLogin(history);
        })
        .catch((err) => {
          console.log(err);
          handlePasswordResetFailed(err);
        });
    }
  };

  return (
    <Modal isOpen={isOpen} contentLabel="Modal">
      <div className="flex flex-col items-center py-5 px-5 sm:px-16 text-sm bg-grey-light">
        <h1 className="text-black font-medium text-2xl mb-5 tracking-tight">
          {language("newPasswordTitle")}
        </h1>
        <PasswordWithValidation
          password={password}
          setPassword={setPassword}
          setPasswordValid={setPasswordValid}
        />
        <PasswordConfirmation
          password={password}
          passwordConfirmation={passwordConfirmation}
          setPasswordConfirmation={setPasswordConfirmation}
        />
        <PasswordInfo />
        <input
          type="submit"
          className="btn mb-5 btn-green max-w-138 w-full h-36"
          onClick={clickHandle}
          value="Save"
        />
      </div>
    </Modal>
  );
};

NewPasswordRequired.propTypes = {
  session: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
};
