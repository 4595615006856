import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";

export const MFAInstructions = () => {
  return (
    <div className="px-4">
      <h3 className="h3 mt-2">Set up two-factor authentication (2FA)</h3>
      <br/>
      <p>
        Your organisation has switched on <strong> two-factor authentication (2FA) </strong>
        to add an extra layer of security to your Breathe account. You&apos;ll now need to
        provide a unique code - as well as your email and password - each time you log in.
      </p>
      <br/>
      <p>
        You&apos;ll need an authenticator app (e.g. Microsoft Authenticator, Google Authenticator,
        Twilio Authy) on your phone - this is where you&apos;ll get your unique code each time you
        log in to Breathe.
      </p>
      <br/>
      <p>
        You&apos;ll need to set up 2FA before you can log in again - click on the button below
        for instructions on how to get started.
      </p>
      <br/>
      <p>
        If you have any questions, speak to your Breathe admin user or check out our knowledge-base.
      </p>

      <div className="flex justify-center my-2">
        <a
          href="https://intercom.help/breathehr/en/articles/9076956-setting-up-two-factor-authentication-2fa"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-blue-400 text-white text-xs rounded p-2 block"
        >
          <FontAwesomeIcon icon={faCog} className="mr-1" />
          Set up instructions
        </a>
      </div>
    </div>
  );
};
