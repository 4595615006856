import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { oauthAuthorize } from "../../api/oauth-authorize";
import { notifyError } from "../../lib/notifications";

export const redirectToClient = (url) => {
  window.location = url;
};

const getQueryParams = (search) => {
  const params = queryString.parse(search);
  return {
    approval_prompt: params.approval_prompt,
    client_id: params.client_id,
    redirect_uri: params.redirect_uri,
    response_type: params.response_type,
    state: params.state,
  };
};

export const useAuthorizeWithDoorkeeper = (setLoading, setCallOnce) => {
  const { search } = useLocation();

  const authorizeWithDoorkeeper = async () => {
    const values = getQueryParams(search);

    try {
      setLoading(true);
      setCallOnce(true);

      const res = await oauthAuthorize(values);
      redirectToClient(decodeURIComponent(res.data.redirect_uri));
      return true;

    } catch (error) {
      setLoading(false);
      setCallOnce(true);

      if (!error.response) {
        notifyError("Network error");
      } else {
        const { status, data } = error.response;

        const errorMessages = {
          400: data.error || "Bad request",
          401: "Incorrect username or password",
        };

        notifyError(errorMessages[status] || "Something went wrong");
      }
    }
  };

  return authorizeWithDoorkeeper;
};
