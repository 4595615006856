import React from "react";
import { Spacer } from "./Spacer";
import { language } from "../settings";

export const PasswordInfo = () => {
  return (
    <>
      <div className="text-gray-500 text-xs max-w-250 text-justify">
        {language("passwordRules")}
      </div>
      <Spacer />
    </>
  )
};
