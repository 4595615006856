import React, { useState } from "react";
import { Helmet } from "react-helmet";
import {
  ConfirmButton,
  Email,
  SingleColumnDiv,
  CenteredDiv,
  GreyDiv,
  NormalHeader,
  SmallText,
  StandardLink,
  GoogleRecaptcha,
  Warning,
} from "../components";
import { notifySuccess, notifyError } from "../lib/notifications";

import { language } from "../settings";
import { forgotPassword } from "../api/forgot-password";
import { checkEmailValid } from "../helpers";

export const ForgottenPassword = () => {
  const url = new URL(window.location.href);
  const email = url.searchParams.get("email") || "";

  const recaptchaRef = React.createRef();

  const [username, setUsername] = useState(email);
  const [hasClicked, sethasClicked] = useState(false);
  const usernameIsNotValidEmail = () => {
    return !checkEmailValid(username);
  };

  const resetPassword = async () => {
    if (usernameIsNotValidEmail()) {
      return notifyError("Please enter a valid email");
    }

    const recaptchaToken = await recaptchaRef.current.executeAsync();
    const body = {
      username,
      recaptchaToken,
    };

    forgotPassword(body)
      .then((_res) => {
        sethasClicked(true);
        setUsername("");

        return notifySuccess("Email with reset password link has been sent");
      })
      .catch((e) => {
        if (!e.response) {
          return notifyError("Network error");
        }

        if (e.response.status === 400) {
          return notifyError("Wrong or expired captcha");
        }

        if (e.response.status === 429) {
          return notifyError(
            "Too many requests, please try again in 15 minutes",
          );
        }

        return notifyError("Something went wrong");
      });

    return true;
  };

  return (
    <>
      <GoogleRecaptcha forwardedRef={recaptchaRef} />
      <Helmet>
        <title>{language("forgottenPassword")}</title>
      </Helmet>
      <SingleColumnDiv>
        <NormalHeader>{language("forgottenPassword")}</NormalHeader>
        <CenteredDiv>
          <SmallText>
            {language("rememberPasswordQuestion")}
            <StandardLink href="/" text=" Back to login" />
          </SmallText>
          <Warning level={ hasClicked ? "info" : "don't show"} icon={false}>
            <p className="text-sm font-bold">
              {language("forgotPasswordTitle")}
            </p>
            <p className="text-xs">{language("forgotPasswordText")}</p>
          </Warning>
          <GreyDiv>
            <Email
              username={username}
              setUsername={setUsername}
            />
          </GreyDiv>
          <ConfirmButton
            text={language("resetPassword")}
            onClick={resetPassword}
          />
        </CenteredDiv>
      </SingleColumnDiv>
    </>
  );
};
