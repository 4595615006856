import React from "react";
import PropTypes from "prop-types";
import { checkEmailValid } from "../helpers";
import { language } from "../settings";

export const Email = ({  username, setUsername }) => {
  const usernameIsNotValidEmail = () => {
    return !checkEmailValid(username);
  };

  const error = () => {
    return username.length > 0 && usernameIsNotValidEmail()
  }

  return (
    <>
      <div className="flex w-full items-center relative">
        <input
          placeholder=" "
          type="email"
          name="email"
          autoComplete="off"
          aria-label="email"
          value={username}
          className={`px-4 py-3 rounded text-black tracking-widest w-full ${
             error() ? "issues" : ""
          }`}
          onChange={(e) => setUsername(e.target.value)}
          id="email-input"
        />
        <label
          htmlFor="email-input"
          className="text-grey absolute ml-4 transition ease-in duration-300 ie-fix pointer-events-none"
        >
          {language("email")}
        </label>
      </div>
    </>
  );
};

Email.propTypes = {
  username: PropTypes.string,
  setUsername: PropTypes.func.isRequired,
};
Email.defaultProps = {
  username: "",
};
