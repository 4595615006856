import React from "react";

export const StandardLink = ({ href, text }) => {
  return (
    <a href={href} className="text-blue-500 hover:text-blue-900 underline">
      {text}
    </a>
  )
};



