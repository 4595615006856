import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

export const AnimatedLink = ({ url, index, children }) => {
  return (
    <motion.a
      href={url}
      className="centered m-2 w-2/5 transform"
      whileHover={{ scale: 1.05 }}
      initial={{ opacity: 0, scale: 0.3 }}
      animate={{
        opacity: 1,
        scale: 1,
        transition: { duration: 0.2, delay: index * 0.15 },
      }}
      exit={{
        opacity: 0,
        scale: 0.5,
        transition: { duration: 0.2, delay: index * 0.15 },
      }}
      key={`applications-${index}`}
    >
      {children}
    </motion.a>
  );
};

AnimatedLink.propTypes = {
  url: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  children: PropTypes.shape({ attribute: PropTypes.string }).isRequired,
};
