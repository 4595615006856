import React from "react";
import ReactModal from "react-modal";
import PropTypes from "prop-types";

ReactModal.defaultStyles.overlay.backgroundColor = "rgb(99, 99, 99, 0.75)";

const customStyles = {
  content : {
    top                   : "50%",
    left                  : "50%",
    right                 : "auto",
    bottom                : "auto",
    marginRight           : "-50%",
    overflow              : "visible",
    transform             : "translate(-50%, -50%)",
  },
};

if (process.env.NODE_ENV !== "test") {
  ReactModal.setAppElement("#root");
} else {
  ReactModal.setAppElement(document.createElement("div"));
}

export const Modal = (props) => {
  const { children } = props;
  return (
    <ReactModal style={customStyles} {...props}>
      {children}
    </ReactModal>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired,
  ]).isRequired,
}
