import React, { useState } from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { Spacer } from "../../components";
import { secretCodeFormatter } from "../../lib/secret-code-formatter";

const SecretCodeInfo = ({ secretCode }) => {
  return (
    <ul className="list-disc px-16 text-sm">
      <li className="text-gray-600">
        Open your authenticator app and tap <strong>Add New Account</strong>
        or the &apos;+&apos; icon.
      </li>
      <li className="text-gray-600">
        Tap <strong> Scan code manually</strong>. In some apps, you&apos;ll need to tap Scan
        QR code first before being given this option.
      </li>
      <li className="text-gray-600">
        Enter the following key: <strong>{secretCodeFormatter(secretCode)}</strong>
      </li>
      <li className="text-gray-600">
        When searching for a logo, type &apos;Breathe HR&apos; and click
        <strong> Search</strong>. Then select the Breathe HR logo that appears.
      </li>
      <li className="text-gray-600">
        In &apos;Account nickname&apos; type &apos;Breathe HR&apos; and click
        <strong> Save</strong>.
      </li>
    </ul>
  );
};

export const SecretCodeRenderer = ({ secretCode }) => {
  const [showSecretCode, setShowSecretCode] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();

    setShowSecretCode(!showSecretCode);
  };

  return (
    <>
      <div className="flex justify-end">
        <button
          type="button"
          onClick={handleClick}
          className="bg-red-400 text-white text-xs rounded p-2 block"
        >
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1" />
          Can&apos;t scan the QR code?
        </button>
      </div>

      <Spacer />
      {showSecretCode && <SecretCodeInfo secretCode={secretCode} />}
    </>
  );
};

SecretCodeRenderer.propTypes = {
  secretCode: PropTypes.string,
};
SecretCodeRenderer.defaultProps = {
  secretCode: "",
};

SecretCodeInfo.propTypes = {
  secretCode: PropTypes.string,
};
SecretCodeInfo.defaultProps = {
  secretCode: "",
};
