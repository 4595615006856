import { createStore } from "easy-peasy";

import actions from "./actions";
import { isIdTokenPresent } from "../lib/auth";

const defaultStore = {
  isAuthenticated: isIdTokenPresent(),
  setIsAuthenticated: actions.setIsAuthenticated,
  profile: null,
  setProfile: actions.setProfile,
  challenge: null,
  setChallenge: actions.setChallenge,
};

const store = createStore(defaultStore);

export default store;
