import { ENV, BREATHE_DOMAIN, LOCALE } from "../constants";

const APP_PORTS = {
  hr: 3000,
  saas: 3000,
  rota: 9000,
  login: 8000,
  rta: 3001,
};

const appUrl = (app) => {
  if (ENV !== "development") {
    return `https://${app}.${BREATHE_DOMAIN}`;
  }
  return `http://${app}.${BREATHE_DOMAIN}:${APP_PORTS[app]}`;
};

const imageUrl = (app) => {
  return `/${app}-${LOCALE}.png`
};

const moduleUrl = (moduleUrl) => {
  return process.env[moduleUrl];
};

export const APPLICATIONS = {
  hr: {
    name: "Breathe HR",
    image: "/hr-logo.png",
    url: appUrl("hr"),
  },
  rota: {
    name: "Breathe Rota",
    image: "/rota-logo.svg",
    url: appUrl("rota"),
  },
  rta: {
    name: "Breathe RTA",
    image: imageUrl("rta"),
    url: moduleUrl("REACT_APP_RTA_URL"),
  },
  saas: {
    name: "Saas Admin",
    image: "/saas-logo.svg",
    url: `${appUrl("hr")}/saas_admins`,
  },
  "partner-hub": {
    name: "Partner hub",
    image: "/partner-hub-logo.png",
    url: `${appUrl("hr")}/partner_company`,
  },
  legacyrota: {
    name: "Breathe Rota",
    image: "/rota-logo.svg",
    url: appUrl("breatherota"),
  },
};
