import React from "react";
import { signOut } from "../api/sign-out";

export const Logout = () => {
  const logout = () => {
    signOut().then(() => {
      window.location = "/";
    });
  };

  return (
    <button
      className="btn btn-white grows hover:bg-red-600 slow"
      onClick={logout}
      type="submit"
    >
      Log Out
    </button>
  );
};
