import { store } from "react-notifications-component";

const defaultOptions = {
  insert: "top",
  container: "top-center",
  dismiss: {
    duration: 5000,
  },
  width: 500,
};

const notify = (message, type) => {
  store.addNotification({ ...defaultOptions, message, type });
}

export const notifySuccess = (message) => {
  notify(message, "success");
};

export const notifyError = (message) => {
  notify(message, "danger");
};
