import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
} from "@fortawesome/free-regular-svg-icons";

export const Eyecon = ({ show, toggleVisibility, passwordPresent }) => {
  return (
    <button
      type="button"
      onClick={toggleVisibility}
      className={`absolute right-0 eyecon ${passwordPresent ? "mr-3rem" : "mr-1-8"}`}
      data-testid="eyecon"
    >
      {show ?
        <FontAwesomeIcon icon={faEyeSlash} /> :
        <FontAwesomeIcon icon={faEye} />}
    </button>
  )
}

Eyecon.propTypes = {
  show: PropTypes.bool,
  passwordPresent: PropTypes.bool,
  toggleVisibility: PropTypes.func,
}
Eyecon.defaultProps = {
  show: false,
  passwordPresent: false,
  toggleVisibility: () => {},
}
