import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useStoreActions } from "easy-peasy";

import { redirectAfterLogin } from "../lib/redirect-after-login";
import { Modal, Spacer } from "../components";
import { softwareTokenMfa } from "../api/software-token-mfa";
import { notifyError } from "../lib/notifications";
import { SoftwareTokenHelp } from "./SoftwareTokenHelp";

export const SoftwareTokenMfa = ({ session, username }) => {
  const setIsAuthenticated = useStoreActions((actions) => actions.setIsAuthenticated);
  const [code, setCode] = useState(null);
  const history = useHistory();

  const codeNotProvided = () => ( code === null || code.length === 0 );

  const backToLoginPage = () => {
    return history.go();
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if(codeNotProvided()) {
      return notifyError("One time code missing");
    }
    const body = { code, session, username };

    return softwareTokenMfa(body)
      .then((_res) => {
        setIsAuthenticated(true);
        return redirectAfterLogin(history);
      })
      .catch((err) => {
        if (!err.response) {
          return notifyError("Network error");
        }

        if (err.response.status === 400) {
          return notifyError("Wrong or expired code");
        }

        if (err.response.status === 401) {
          return backToLoginPage();
        }

        return notifyError("Something went wrong");
      });
  }

  return (
    <Modal isOpen contentLabel="Modal">
      <div className="flex flex-col items-center py-5 px-5 sm:px-16 text-sm bg-grey-light">
        <h1 className="text-black font-medium text-2xl mb-5 tracking-tight">
          We&apos;re just checking if it&apos;s really you
        </h1>
        <p>Enter your one-time code from your authenticator app</p>
        <Spacer />
        <form onSubmit={handleSubmit} className="flex flex-col w-full mb-4">
          <input
            className="px-4 py-3 rounded text-black tracking-widest max-w-200 mb-5 self-center"
            aria-label="authorization-code"
            autoFocus
            placeholder="Enter Code"
            onChange={(e) => setCode(e.target.value)}
            type="text"
          />
          <input
            type="submit"
            className="btn mb-5 btn-green max-w-138 w-full h-36 self-center"
            onClick={handleSubmit}
            value="Submit"
          />
          <SoftwareTokenHelp />
        </form>
      </div>
    </Modal>
  )
};

SoftwareTokenMfa.propTypes = {
  session: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
}
