import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedinIn,
  faYoutube,
  faFacebookSquare,
} from "@fortawesome/free-brands-svg-icons";

export const Footer = () => {
  const date = new Date().getFullYear();
  return (
    <footer className="w-full bg-white border-gray-100 border-2 py-10">
      <div className="max-w-1240 mx-auto float-none px-3">
        <div className="flex items-center justify-between flex-col md:flex-row">
          <div className="flex flex-row mr-2">
            <a
              href="https://www.facebook.com/breatheHR"
              target="_blank"
              rel="noopener noreferrer"
              className="fb text-2xl flex items-center mr-10"
            >
              <FontAwesomeIcon icon={faFacebookSquare} aria-hidden />
            </a>
            <a
              href="https://www.linkedin.com/company/breathehr"
              target="_blank"
              rel="noopener noreferrer"
              className="in text-2xl flex items-center mr-10"
            >
              <FontAwesomeIcon icon={faLinkedinIn} aria-hidden />
            </a>
            <a
              href="https://www.youtube.com/user/breatheHR"
              target="_blank"
              rel="noopener noreferrer"
              className="yt text-2xl flex items-center mr-10"
            >
              <FontAwesomeIcon icon={faYoutube} aria-hidden />
            </a>
          </div>

          <div className="copyright flex flex-col md:flex-row space-between">
            <div className="mr-10">
              Registered in England, Company Number 3020608
            </div>
            <div>{`© Copyright ${date} Centurion, all rights reserved`}</div>
          </div>
        </div>
      </div>
    </footer>
  );
};
