import React from "react";
import PropTypes from "prop-types";
import { settings, language } from "../settings";

export const OtherLogins = ({ setError }) => {
  const { oAuth, bioLogin } = settings;
  return (
    <>
      {oAuth?.apple && (
        <button
          type="button"
          className="btn bg-black rounded-full text-white grows min-w-1/4 btn-icon mb-1 px-4 focus:outline-none focus:shadow-outline"
          onClick={() => {
            setError("Not how apple sign-ins work 🙂");
          }}
        >
          <img
            src="/apple_logo.svg"
            alt="Apple Logo"
            height="10"
            width="10"
            className="mr-1"
          />
          <span>{language("appleSignInButton")}</span>
        </button>
      )}
      {bioLogin?.iOs && (
        <button
          className="btn bg-transparent border border-blue-500 text-blue-500 grows min-w-1/4 btn-icon mb-1 focus:outline-none focus:shadow-outline"
          onClick={() => {
            setError("This button is merely here to look pretty");
          }}
          type="button"
        >
          <img
            src="/apple_face_id.svg"
            alt="Apple Face ID symbol"
            height="20"
            width="20"
            className="mr-1"
          />
          <span>{language("appleBioButton")}</span>
        </button>
      )}
    </>
  );
};

OtherLogins.propTypes = {
  setError: PropTypes.func.isRequired,
};
