import { action } from "easy-peasy";

const setProfile = action((state, payload) => {
  return {
    ...state,
    profile: payload,
  }
});

const setIsAuthenticated = action((state, payload) => {
  return {
    ...state,
    isAuthenticated: payload,
  }
});

const setChallenge = action((state, payload) => {
  return {
    ...state,
    challenge: payload,
  }
});

const actions = {
  setProfile,
  setIsAuthenticated,
  setChallenge,
};

export default actions;