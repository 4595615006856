import React, { useState } from "react";
import PropTypes from "prop-types";
import { language } from "../settings";
import { Eyecon } from ".";
import { FieldErrorIcon } from "./FieldErrorIcon";

export const PasswordWithValidation = ({
  password,
  setPassword,
  setPasswordValid,
  label,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const toggleVisibility = () => {
    setShowPassword((current) => !current)
  }
  const MIN_PASSWORD_LENGTH = 8;

  const minLength = (pass) => {
    return pass.length >= MIN_PASSWORD_LENGTH;
  };

  const containsLowerCase = (pass) => {
    return pass.toUpperCase() !== pass;
  };

  const containsUpperCase = (pass) => {
    return pass.toLowerCase() !== pass;
  };

  const containsNumber = (pass) => {
    return /\d/g.test(pass);
  };

  const noLeadingSpace = (pass) => {
    return /(?=^\S)/.test(pass);
  };

  const noTrailingSpace = (pass) => {
    return /[\S]$/.test(pass);
  }

  const isPasswordValid = (pass) => {
    if (
      minLength(pass) &&
      containsLowerCase(pass) &&
      containsUpperCase(pass) &&
      containsNumber(pass) &&
      noLeadingSpace(pass) &&
      noTrailingSpace(pass)
    ) {
      return true;
    }

    return false;
  };

  const collectErrors = () => {
    const errors = [];

    if (!minLength(password)) errors.push("At least 8 characters");
    if (!containsLowerCase(password))
      errors.push("At least 1 lowercase letter");
    if (!containsUpperCase(password))
      errors.push("At least 1 uppercase letter");
    if (!containsNumber(password)) errors.push("At least 1 number");
    if (!noLeadingSpace(password)) errors.push("No space at beginning");
    if (!noTrailingSpace(password)) errors.push("No space at end");

    return errors;
  };

  const onChange = (e) => {
    setPassword(e.target.value);
    setPasswordValid(isPasswordValid(e.target.value));
  };

  return (
    <>
      <div className="flex w-full items-center relative mb-5">
        <input
          placeholder=" "
          type={showPassword ? "text" : "password"}
          name="password"
          autoComplete="off"
          aria-label={label}
          value={password}
          className={`password-input px-4 py-3 rounded text-black tracking-widest w-full ${
            password.length > 0 && collectErrors().length > 0 ? "issues" : ""
          }`}
          onChange={onChange}
        />
        <label
          htmlFor="password-input"
          className="text-grey absolute ml-4 transition ease-in duration-300 ie-fix pointer-events-none"
        >
          {label}
        </label>
        <Eyecon
          show={showPassword}
          toggleVisibility={toggleVisibility}
          passwordPresent={password.length > 0}
        />
        <div
          className={`transition-all duration-300 ml-1 ${
            password.length > 0 ? "w-6" : "w-0"
          }`}
        >
          {password.length > 0 && <FieldErrorIcon errors={collectErrors()} />}
        </div>
      </div>
    </>
  );
};

PasswordWithValidation.propTypes = {
  password: PropTypes.string.isRequired,
  setPassword: PropTypes.func.isRequired,
  setPasswordValid: PropTypes.func.isRequired,
  label: PropTypes.string,
};

PasswordWithValidation.defaultProps = {
  label: language("password"),
};
