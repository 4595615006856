import React from "react";
import PropTypes from "prop-types";

import { OtherLogins } from "./OtherLogins";
import { SignInWithGoogle } from "./SignInWithGoogle";
import { SignupLink } from "./SignupLink";

import { language } from "../settings";

export const LoginLinks = ({ username, setError }) => {
  return (
    <>
      <div className="text-grey mb-4 text-center">
        <a
          href={`/forgotten-password${username ? `?email=${username}` : ""}`}
          className="href-blue hover:text-blue-600 underline"
        >
          {language("forgotPasswordLink")}
        </a>
      </div>

      {false && <SignInWithGoogle />}
      <SignupLink />
      <OtherLogins setError={setError} />
    </>
  );
};

LoginLinks.propTypes = {
  username: PropTypes.string.isRequired,
  setError: PropTypes.func.isRequired,
};
