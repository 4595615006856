import { useMemo } from "react";
import { ENV } from "../constants";

const useEnvironment = () => {
  const isProd = useMemo(() => {
    return ENV === "production";
  }, []);

  return {
    isProd,
  };
};

export default useEnvironment;
