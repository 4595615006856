import React from "react";
import PropTypes from "prop-types";

export const Warning = ({ level, children, icon = true }) => {
  let colour = "teal";
  if (level === "warning") colour = "red";
  return ["warning", "info", "true"].includes(level) ? (
    <div
      className={`mb-5 mx-10 bg-${colour}-100 border-t-4 border-${colour}-500 rounded-b text-${colour}-900 px-4 py-3 shadow-md`}
      role="alert"
    >
      <div className="flex">
        {icon && (
          <div className="py-1">
            <svg
              className={`fill-current h-6 w-6 text-${colour}-500 mr-4`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
            </svg>
          </div>
        )}
        <div>{children}</div>
      </div>
    </div>
  ) : (
    <></>
  );
};
Warning.propTypes = {
  level: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired,
  ]).isRequired,
  icon: PropTypes.bool,
};
Warning.defaultProps = {
  level: "don't show",
  icon: true,
};
