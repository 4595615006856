import React from "react";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { settings, animations } from "../../../settings";
import {
  Password,
  Email,
} from "../../../components";

export const redirectToClient = (url) => {
  window.location = url;
}

export const LoginForm = ({
  username,
  setUsername,
  password,
  setPassword,
  loading,
  usernameIsValidEmail,
  usernameIsNotValidEmail,
  signIn,
}) => {

  const { animate } = settings;
  const { block, form } = animations;

  return (
    <>
      <Helmet>
        <title>Sign in with Breathe</title>
      </Helmet>
      <h1 className="text-center text-black font-medium text-2xl mb-8 tracking-tight">
        Sign in with Breathe
      </h1>

      <motion.form
        variants={form}
        initial={animate ? "hidden" : ""}
        animate={animate ? "show" : ""}
      >
        <motion.div
          variants={block}
          className="flex flex-col w-full pb-4 pt-10 px-4 sm:px-16 text-sm bg-grey-light "
        >
          <Email
            username={username}
            setUsername={setUsername}
          />
        </motion.div>

        <motion.div
          variants={block}
          className="flex flex-col w-full px-4 sm:px-16 text-sm bg-grey-light"
        >
          <Password
            password={password}
            setPassword={setPassword}
          />
        </motion.div>

        <motion.div variants={block} className="centered bg-grey-light pb-6">
          <button
            type="submit"
            id="submit_button"
            className={`btn mb-2 btn-green max-w-138 w-full h-36
                ${usernameIsValidEmail() ? "grows" : "disabled"}`}
            onClick={(e) => {
              e.preventDefault();
              signIn();
            }}
            disabled={usernameIsNotValidEmail()}
          >
            {loading ? (
              <FontAwesomeIcon icon={faSpinner} spin aria-hidden />
            ) : (
              "Sign in"
            )}
          </button>
        </motion.div>
      </motion.form>
    </>
  );
};

LoginForm.propTypes = {
  username: PropTypes.string.isRequired,
  setUsername: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  setPassword: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  usernameIsValidEmail: PropTypes.func.isRequired,
  usernameIsNotValidEmail: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
};