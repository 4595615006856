import React from "react";
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";

import { RECAPTCHA_SITE_KEY } from "../constants";

export const GoogleRecaptcha = ({ forwardedRef }) => {
  return (
    <ReCAPTCHA
      ref={forwardedRef}
      size="invisible"
      sitekey={RECAPTCHA_SITE_KEY}
    />
  );
};

GoogleRecaptcha.propTypes = {
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(HTMLInputElement) }),
  ]).isRequired,
};
