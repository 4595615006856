import React from "react";
import PropTypes from "prop-types";

export const ConfirmButton = ({ text, onClick, disabled }) => {
  return (
    <button
      className={`btn btn-green min-w-1/4 mb-2 ${
        !disabled ? "grows" : "disabled"
      }`}
      type="submit"
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

ConfirmButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
ConfirmButton.defaultProps = {
  disabled: false,
};
