import { parse } from "query-string";
import { APPLICATIONS } from "./applications";

export const querystring = (query) => {
  if (Object.entries(query).length === 0) {
    return ""
  }

  return `?${Object.keys(query).map((key) => `${key  }=${  query[key]}`).join("&")}`
}

export const appUrl = (redirect_app, redirect_path) => {
  const { url } = APPLICATIONS[redirect_app];

  return `${url}${redirect_path}`;
}

export const redirectAfterLogin = (history) => {
  const { search } = history.location;
  const queries = parse(search);
  const { redirect_app, redirect_path = "", ...query } = queries;

  if(!APPLICATIONS[redirect_app]) {
    return history.push("/applications");
  }

  window.location = `${appUrl(redirect_app, redirect_path)}${querystring(query)}`
  return null;
}
