import React from "react";
import { language } from "../settings";

export const SignupTabs = () => {
  return (
    <div className="flex h-8 mb-2 shadow-2xl cursor-pointer">
      <div className="font-bold w-1/2 text-center border-b-4 border-blue-500 ">
        {language("login")}
      </div>
      <div className="font-bold w-1/2 text-center text-gray-400">
        {language("signup")}
      </div>
    </div>
  );
};
