import React from "react";

export const CenteredDiv = ({ children }) => {
  return (
    <div className="my-2 centered">
      {children}
    </div>
  )
};

